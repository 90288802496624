.AccountsPopup {
    height: 500px;
    overflow: scroll;
}

.Accordion {
    margin: 0px !important;
    min-height: unset !important;
    margin-bottom: 32px !important;
}
.Accordion {
    /* border: 1px solid peru !important; */
}
.AccordionSummary,
.AccordionSummary * {
    padding: 0px !important;
    min-height: unset !important;
}
.AccordionSummary div {
    margin: unset !important;

    /* border: 1px solid blue; */
}
.AccordionDetails div {
    /* border: 1px solid green !important; */
    margin: unset !important;
    height: unset !important;
    margin-bottom: 32px !important;
}

.AccordionDetails div:first-child {
    margin-top: 32px !important;
}
.AccordionDetails div:last-child {
    margin-bottom: 0 !important;
}

.AccordionDetails {
    /* border: 4px solid purple !important; */

    display: flex;
    flex-direction: column;
}

.AccordionDetails,
.AccordionDetails * {
    padding: 0px !important;
    padding-left: 10px !important;
    min-height: unset !important;
}
.AccordionDetails .MuiAccordion-region {
    /* border: 1px solid red !important; */
}
