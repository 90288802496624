* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: auto;
  font-family: Poppins;
}

html,
body,
#root {
  height: 100%;
  overflow: overlay;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Change the white to any color when using autocomplete*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

a{
  text-decoration: none;
}